<template>
  <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.888 1.83126C23.8546 1.81707 23.8177 1.8132 23.782 1.82015C23.7464 1.8271 23.7136 1.84455 23.688 1.87026L13.941 11.6153C13.4253 12.1307 12.7261 12.4202 11.997 12.4202C11.2679 12.4202 10.5687 12.1307 10.053 11.6153L0.310002 1.87026C0.28451 1.84431 0.251754 1.8267 0.216052 1.81973C0.180349 1.81277 0.143375 1.81679 0.110002 1.83126C0.0772087 1.84538 0.0492947 1.86884 0.0297354 1.89872C0.0101762 1.92859 -0.000164108 1.96355 1.96956e-06 1.99926V13.9993C1.96956e-06 14.5297 0.210716 15.0384 0.585788 15.4135C0.960861 15.7885 1.46957 15.9993 2 15.9993H22C22.5304 15.9993 23.0391 15.7885 23.4142 15.4135C23.7893 15.0384 24 14.5297 24 13.9993V1.99926C24.0001 1.9633 23.9896 1.92811 23.9696 1.89819C23.9497 1.86827 23.9212 1.84497 23.888 1.83126Z"
      fill="#28294E"
    />
    <path
      d="M11.1146 10.556C11.3493 10.79 11.6672 10.9214 11.9986 10.9214C12.3301 10.9214 12.648 10.79 12.8826 10.556L22.5686 0.87C22.6342 0.804424 22.6803 0.721904 22.7016 0.631664C22.723 0.541423 22.7188 0.447018 22.6896 0.359C22.5796 0.0300002 22.2736 0 21.9996 0H1.99964C1.72464 0 1.41664 0.0300002 1.30864 0.359C1.27944 0.447018 1.27529 0.541423 1.29666 0.631664C1.31803 0.721904 1.36407 0.804424 1.42964 0.87L11.1146 10.556Z"
      fill="#28294E"
    />
  </svg>
</template>
